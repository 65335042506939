@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./styles.css"; /* This is for additional custom styles */

@font-face {
  font-family: "Circular Std Black";
  font-style: normal;
  font-weight: normal;
  src: local("Circular Std Black"),
    url("./assets/fonts/CircularStd-Black.woff") format("woff");
}

@font-face {
  font-family: "Circular Std Book";
  font-style: normal;
  font-weight: normal;
  src: local("Circular Std Book"),
    url("./assets/fonts/CircularStd-Book.woff") format("woff");
}

@font-face {
  font-family: "Circular Std Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Circular Std Medium"),
    url("./assets/fonts/CircularStd-Medium.woff") format("woff");
}

@font-face {
  font-family: "KT Nirma Custom";
  font-style: normal;
  font-weight: bold;
  src: local("KT Nirma"), url("./assets/fonts/kt-nirma.woff") format("woff");
}
@font-face {
  font-family: "Circular Std Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Circular Std Bold"),
    url("./assets/fonts/CircularStd-Bold.woff") format("woff");
}

body {
  font-family: Circular Std Medium, sans-serif;
}

body p {
  font-family: Circular Std Book, sans-serif;
}

.navbar > div > header {
  background-color: rgb(255, 255, 255, 0.17);
}

h1 {
  font-weight: bold;
  line-height: 4rem;
}

/* hero section bg overlay */

.wrapper {
  background-image: url("./assets/images/gradBg.png");
  background-size: 50%;
  background-position: top;
}

/* Button hover effects */
.button-grad:hover .button-inner-fill {
  background: white;
  transition: all 0.2s ease-in;
}

.button-grad2:hover i,
.button-grad2:hover span {
  color: white;
}

.wrapper {
  background-color: white;
}

.nav-button {
  transform: scale(1);
  transition: all 0.2s ease-out;
}

.nav-button:hover {
  cursor: pointer;
  transform: scale(1.03);

  transition: all 0.2s ease-out;
  color: white;
}

.scroll-marker {
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

/* Add this to your CSS file */

/* Hide the menu by default on mobile */
.navbar .lg\:hidden {
  display: block;
}

.navbar .lg\:flex {
  display: flex;
}

@media (max-width: 765px) {
  .navbar .lg\:hidden {
    display: nsone;
  }
  .wrapper {
    background-size: cover;
  }

  .navbar .lg\:flex {
    display: none;
  }

  .navbar .flex {
    display: flex;
  }

  .navbar .hidden {
    display: none;
  }

  .navbar .absolute {
    position: absolute;
  }

  .navbar .top-16 {
    top: 4rem; /* Adjust as needed */
  }

  .navbar .left-0 {
    left: 0;
  }

  .navbar .w-full {
    width: 100%;
  }

  .navbar .lg\:static {
    position: static;
  }

  .navbar .lg\:top-auto {
    top: auto;
  }

  .navbar .lg\:left-auto {
    left: auto;
  }

  .navbar .lg\:w-auto {
    width: auto;
  }

  .navbar .bg-white {
    background-color: white;
  }

  .navbar .lg\:bg-transparent {
    background-color: transparent;
  }

  .navbar .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .navbar .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
}
